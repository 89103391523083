<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="name"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 竖版封面 -->
      <a-form-item
        label="前台剧名"
      >
        {{ detail.drama && detail.drama.name }}
      </a-form-item>
      <!-- 竖版封面 -->
      <!-- <a-form-item
        label="竖版封面"
        name="verticalCover"
        :rules="[{ required: true, validator: validator }]"
      >
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="BaseInfo"
            :fileNumber="5"
            :beforeUploadPro="beforeUploadPro"
            :fileList="formState.verticalCover"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">支持.png .jpg .gif</div>
        <div class="form-upload-list" v-if="formState.verticalCover.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.verticalCover"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('verticalCover', index)"
          >
            <a-form-item class="checkbox-form">
              <a-checkbox
                v-model:checked="item.is_choose_boolean"
                class="upload-item-select"
                @change="changeSelectCover('verticalCover', index)"
              >
              </a-checkbox>
            </a-form-item>
          </ImageView>
        </div>
      </a-form-item> -->
      <!-- 横版封面 -->
      <!-- <a-form-item
        label="横版封面"
        name="horizontalCover"
      >
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="BaseInfo"
            :fileNumber="5"
            :imgSizeMode="22"
            :imgSizeWidth="208"
            :imgSizeHeight="124"
            imgSizeError="图片最小尺寸为 208 * 124"
            :imgScaleMode="1"
            :imgScaleWidth="208"
            :imgScaleHeight="124"
            imgScaleError="图片尺寸比例不正确"
            :beforeUploadPro="beforeUploadPro"
            :fileList="formState.horizontalCover"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        
        <div class="form-upload-hint">支持.png .jpg .gif，208×124，或尺寸更大的等比图片</div>
        <div class="form-upload-list" v-if="formState.horizontalCover.length">
          <ImageView
            class="form-upload-item horizontal"
            v-for="(item, index) in formState.horizontalCover"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('horizontalCover', index)"
          >
            <a-form-item class="checkbox-form">
              <a-checkbox
                v-model:checked="item.is_choose_boolean"
                class="upload-item-select"
                @change="changeSelectCover('horizontalCover', index)"
              >
              </a-checkbox>
            </a-form-item>
          </ImageView>
        </div>
      </a-form-item> -->
      <!-- 视频集锦 -->
      <!-- <a-form-item
        label="视频集锦"
        name="videoHighlight"
        :rules="[{ required: true, validator: validator }]"
      >
        <a-form-item-rest>
          <Upload
            accept=".mp4"
            uploadSource="video_highlight"
            :fileNumber="5"
            :beforeUploadPro="beforeUploadVideoPro"
            :fileList="formState.videoHighlight"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">支持.mp4，2分钟之内的视频，主要用作付费引导</div>
        <div class="form-upload-list" v-if="formState.videoHighlight.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.videoHighlight"
            :key="index"
            :isShowPlay="true"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteVideo(index)"
            @play="touchPlayVideo(index, item)"
          >
            <a-form-item class="checkbox-form">
              <a-checkbox
                v-model:checked="item.is_choose_boolean"
                class="upload-item-select"
                @change="changeSelectCover('videoHighlight', index)"
              >
              </a-checkbox>
            </a-form-item>
          </ImageView>
        </div>
      </a-form-item> -->
      <!-- 剧情标签 -->
      <a-form-item
        label="剧情标签"
        name="plotTag"
      >
        <a-select
          mode="multiple"
          placeholder="请选择"
          v-model:value="formState.plotTag"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="tag in tags"
            :key="`${tag.id}-${tag.name}`"
            :value="tag.id"
          >
            {{ tag.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 封面角标 -->
      <a-form-item
        label="封面角标"
        name="cornerMark"
      >
        <a-select
          v-model:value="formState.cornerMark"
          placeholder="请选择"
        >
          <!-- 手动清除角标 -->
          <a-select-option :value="0">无</a-select-option>
          <a-select-option
            v-for="mask in CornerMasks"
            :key="mask.value"
            :value="mask.value"
          >
            {{ mask.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 基础权重 -->
      <a-form-item
        label="基础权重"
        name="basicWeight"
        :rules="[{ required: true, validator: validator }]"
      >
        <a-input
          placeholder="请输入1-1000，支持2位小数"
          v-model:value="formState.basicWeight"
        />
      </a-form-item>
      <!-- 基础播放量 -->
      <a-form-item
        label="基础播放量"
        name="basicPlayNum"
        :rules="[{ required: true, message: '请输入基础播放量' }]"
      >
        <a-input
          style="width: 200px; margin-right: 10px;"
          placeholder="请输入"
          v-model:value="formState.basicPlayNum"
        />
        <a class="a-link" @click="setBasicPlayNum(1000)">千级</a>
        <a class="a-link" @click="setBasicPlayNum(10000)">万级</a>
        <a class="a-link" @click="setBasicPlayNum(100000)">十万级</a>
        <a class="a-link" @click="setBasicPlayNum(1000000)">百万级</a>
      </a-form-item>
      <!-- 付费起始 -->
      <a-form-item
        class="form-item-clear"
        label="付费起始"
        :rules="[{ required: true }]"
      >
        <a-row>
          <!-- 自定义 -->
          <a-col :span="12">
            <a-form-item
              name="payFrom"
              :rules="[{ required: true, validator: validator }]"
            >
              <a-input
                style="width: 200px; margin-right: 18px;"
                placeholder="请输入"
                suffix="集起"
                v-model:value="formState.payFrom"
                :disabled="formState.payFromChecked"
              />
            </a-form-item>
          </a-col>
          <!-- 系统默认 -->
          <a-col :span="12" style="padding-left: 10px;">
            <a-form-item
              name="payFromChecked"
            >
              <a-checkbox v-model:checked="formState.payFromChecked">
                系统默认 {{ detail.project_setting ? detail.project_setting.start_pay_eq : 0 }}
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>
      <!-- 每集收费 -->
      <a-form-item
        class="form-item-clear"
        label="每集收费"
        :rules="[{ required: true }]"
      >
        <a-row>
          <!-- 自定义 -->
          <a-col :span="12">
            <a-form-item
              name="perPay"
              :rules="[{ required: true, validator: validator }]"
            >
              <a-input
                style="width: 200px; margin-right: 18px;"
                placeholder="请输入"
                suffix="嗨豆"
                v-model:value="formState.perPay"
                :disabled="formState.perPayChecked"
              />
            </a-form-item>
          </a-col>
          <!-- 系统默认 -->
          <a-col :span="12" style="padding-left: 10px;">
            <a-form-item
              name="perPayChecked"
            >
              <a-checkbox v-model:checked="formState.perPayChecked">
                系统默认 {{ detail.project_setting ? detail.project_setting.fee : 0 }}
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>
      <!-- 广告解锁 -->
      <a-form-item
        label="广告解锁"
        name="ad_unlock"
      >
        <a-switch v-model:checked="formState.ad_unlock" :disabled="formState.ad_unlock_disabled" />
      </a-form-item>
      <!-- 下部轮播 -->
      <a-form-item
        label="下部轮播"
        name="nextDramaID"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          show-search
          style="width: 200px;"
          v-model:value="formState.nextDramaID"
          placeholder="请选择"
          :filter-option="filterOption"
        >
          <a-select-option
            v-for="item in dramas"
            :key="`${item.project_drama_id}-${item.remark_name}`"
            :value="item.project_drama_id"
          >
            {{ item.remark_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 上架状态 -->
      <a-form-item
        label="上架状态"
        name="shelfChecked"
      >
        <a-switch v-model:checked="formState.shelfChecked" :disabled="shelfDisabled" />
      </a-form-item>
      <div class="date-range">授权期限为 {{ detail.copyright_start_at }} 至 {{ detail.copyright_end_at }}</div>
      <!-- 前台首页展示 -->
      <a-form-item
        label="前台首页展示"
        name="isShow"
      >
        <a-switch v-model:checked="formState.isShow" />
      </a-form-item>
      <!-- 达人推广 -->
      <a-form-item
        label="达人推广"
        name="talent_promotion"
      >
        <a-switch v-model:checked="formState.talent_promotion" />
      </a-form-item>
      <!-- 达人素材 -->
      <a-form-item
        v-if="formState.talent_promotion"
        label="达人素材"
        name="talent_material"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.talent_material"
          placeholder="请选择"
        >
          <a-select-option
            v-for="mask in TalentMaterials"
            :key="mask.value"
            :value="mask.value"
          >
            {{ mask.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 预览 -->
    <PreviewModal ref="RefPreviewModal"></PreviewModal>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Upload from '@/components/Upload'
import ImageView from '@/components/ImageView'
import PreviewModal from '@/components/preview/PreviewModal'
import Pub from '@/utils/public'
import { ClassifySettings, CornerMasks, TalentMaterials } from '@/utils/constantList'
import { getAllClassifyList } from '@/api/system'
import { getDramaDetail, editDrama, projectDramaAll } from '@/api/operate'
// 引入 dayjs 及 扩展函数
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

const emit = defineEmits('success')
// Form实例
const RefForm = ref(null)
// 视频预览
const RefPreviewModal = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 标签列表
let tags = ref([])
// 短剧列表
let dramas = ref([])
// 片库ID、name
let id = ref(undefined)
let name = ref(undefined)
// 剧集详情
let detail = ref({})
// 剧集信息
let formState = reactive({
  // 竖版封面
  verticalCover: [],
  // 横版封面
  horizontalCover: [],
  // 视频集锦
  videoHighlight: [],
  // 剧情标签
  plotTag: [],
  // 封面角标
  cornerMark: undefined,
  // 基础权重
  basicWeight: undefined,
  // 下部轮播
  nextDramaID: undefined,
  // 基础播放量
  basicPlayNum: undefined,
  // 付费起始集数
  payFrom: undefined,
  //  付费起始勾选默认
  payFromChecked: false,
  // 每集收费
  perPay: undefined,
  // 每集收费勾选默认
  perPayChecked: false,
  // 广告解锁
  ad_unlock: false,
  // 上架状态勾选
  shelfChecked: false,
  // 前台首页展示
  isShow: true,
  // 达人推广
  talent_promotion: false,
  // 达人素材
  talent_material: undefined
})
// 是否禁用上架switch
let shelfDisabled = ref(false)

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 打开抽屉
function showDrawer (record) {
  visible.value = true
  id.value = record.id
  name.value = record.remark_name
  // 获取标签列表（获取详情 必须在 获取标签成功 以后，兼容已停用标签问题）
  getTags()
  // 获取剧集列表
  getProjectDramaAll()
}

// 获取标签列表
function getTags () {
  const params = {
    source: ClassifySettings.tag,
    // 1 隐藏停用
    status: 1,
    page: 1,
    page_size: 10000
  }
  getAllClassifyList(params).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      tags.value = data.list
      // 获取详情
      getDetail()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 短剧列表
function getProjectDramaAll () {
  projectDramaAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
    }
  })
}

// 获取详情
function getDetail () {
  isLoading.value = true
  getDramaDetail({
    id: id.value
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 存储详情
      detail.value = data.info
      // 将【选中的并且已停用】的标签列表，拼接在正常的标签列表之后
      const deactiveTags = data.info.tags.filter(item => item.status === 0)
      if (deactiveTags.length) {
        tags.value = tags.value.concat(deactiveTags)
      }
      // 设置表单显示
      nextTick(() => {
        const info = data.info
        // 竖版封面
        formState.verticalCover = info.s_cover.map(item => {
          item.is_choose_boolean = item.is_choose ? true : false
          return item
        })
        // 横版封面
        formState.horizontalCover = info.h_cover.map(item => {
          item.is_choose_boolean = item.is_choose ? true : false
          return item
        })
        // 视频集锦
        // formState.videoHighlight = info.highlights.map(item => {
        //   item.is_choose_boolean = item.is_choose ? true : false
        //   return item
        // })
        // 剧情标签
        formState.plotTag = info.tags.map(item => item.id)
        // 封面角标
        formState.cornerMark = info.cover_tag || undefined
        // 基础权重
        formState.basicWeight = info.weight
        // 下部轮播
        formState.nextDramaID = info.next_project_drama_id
        // 基础播放量
        formState.basicPlayNum = info.play_count
        // 付费起始集数
        formState.payFrom = info.start_pay_eq
        //  付费起始勾选默认
        formState.payFromChecked = info.default_pay_button ? true : false
        // 每集收费
        formState.perPay = info.fee
        // 每集收费勾选默认
        formState.perPayChecked = info.default_fee_button ? true : false
        // 广告解锁
        formState.ad_unlock = info.ad_unlock ? true : false
        formState.ad_unlock_disabled = info.drama.drama_ad_unlock ? false : true
        // 上架状态勾选
        formState.shelfChecked = info.status ? true : false
        // 禁用上下架状态（仅在授权日期之间可点击上架）
        if (dayjs().isBetween(info.copyright_start_at, info.copyright_end_at, 'day', '[]')) {
          shelfDisabled.value = false
        } else {
          shelfDisabled.value = true
        }
        // 前台首页展示
        formState.isShow = info.is_show ? true : false
        // 达人推广
        formState.talent_promotion = info.talent_promotion ? true : false
        // 上架状态勾选
        formState.talent_material = info.talent_material
      })
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    RefForm.value.resetFields()
    visible.value = false
  }
}

// 准备上传（图片）
function beforeUploadPro (file) {
  if (!file.type.includes('image')) {
    message.error('请上传图片类型的文件')
    return false
  }
  return true
}

// 准备上传（视频）
function beforeUploadVideoPro (file) {
  return new Promise((resolve, reject) => {
    // 视频类型
    if (!file.type.includes('video')) {
      message.error('请上传正确的视频类型文件')
      reject()
      return
    }
    // 视频时长
    var url = URL.createObjectURL(file)
    var audioElement = new Audio(url)
    audioElement.addEventListener("loadedmetadata", () => {
      // 超过指定时长
      if (Math.trunc(audioElement.duration) > 120) {
        message.error('请上传时长2分钟之内的视频文件')
        reject()
      } else {
        // 允许上传
        resolve()
      }
    })
  })
}


// 表单校验
function validator (rule, value) {
  // 封面
  if (rule.field === 'verticalCover') {
    if (value && value.length === 0) {
      return Promise.reject('请上传封面')
    }
    if (!value.some(item => item.is_choose_boolean)) {
      return Promise.reject('必须选中一个封面')
    }
    return Promise.resolve()
  }
  // 视频集锦
  // if (rule.field === 'videoHighlight') {
  //   if (value && value.length === 0) {
  //     return Promise.reject('请上传视频集锦')
  //   }
  //   if (!value.some(item => item.is_choose_boolean)) {
  //     return Promise.reject('必须选中一个视频集锦')
  //   }
  //   return Promise.resolve()
  // }
  // 付费起始（不得大于短剧总集数）
  else if (rule.field === 'payFrom') {
    // 若勾选默认，则不校验
    if (formState.payFromChecked) {
      return Promise.resolve()
    }
    // 否则校验自身
    if (!value) {
      return Promise.reject('请输入付费起始')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    } else if (value > detail.value.drama.total_ep + 1) {
      return Promise.reject('不得大于短剧总集数 + 1')
    }
    // else if (value > detail.value.drama.total_ep) {
    //   return Promise.reject('不得大于短剧总集数')
    // }
    return Promise.resolve()
  }
  // 每集收费（必须为偶数）
  else if (rule.field === 'perPay') {
    // 若勾选默认，则不校验
    if (formState.perPayChecked) {
      return Promise.resolve()
    }
    // 否则校验自身
    if (!value) {
      return Promise.reject('请输入每集收费')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    } else if (!Pub.IS_EVEN_NUMBER(value)) {
      return Promise.reject('必须为偶数')
    }
    return Promise.resolve()
  }
  // 基础权重
  else if (rule.field === 'basicWeight') {
    if (!value) {
      return Promise.reject('请输入基础权重')
    } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value) || value > 1000 || value < 1) {
      return Promise.reject('必须为1-1000，支持2位小数')
    }
    return Promise.resolve()
  }
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}
// 处理封面、视频集锦为单选
function changeSelectCover (coverKey, index) {
  const bool = formState[coverKey][index].is_choose_boolean
  if (bool) {
    formState[coverKey].forEach((item, i) => {
      if (i !== index) {
        item.is_choose_boolean = false
      }
    })
  }
}

// 移除视频集锦
function touchDeleteVideo (index) {
  formState.videoHighlight.splice(index, 1)
}
// 预览视频集锦
function touchPlayVideo (index, item) {
  RefPreviewModal.value.showModal(item.url)
}

// 设置基础播放量随机数
function setBasicPlayNum (level) {
  formState.basicPlayNum = Pub.RANDOM(level)
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((() => {
    isLoading.value = true
    // 根据布尔值处理封面选中
    formState.verticalCover.forEach(item => {
      item.is_choose = item.is_choose_boolean ? 1 : 0
    })
    formState.horizontalCover.forEach(item => {
      item.is_choose = item.is_choose_boolean ? 1 : 0
    })
    // 视频集锦处理
    // formState.videoHighlight.forEach(item => {
    //   item.is_choose = item.is_choose_boolean ? 1 : 0
    // })
    // 准备数据
    const params = {
      id: detail.value.id,
      s_cover: formState.verticalCover,
      h_cover: formState.horizontalCover,
      highlights: formState.videoHighlight,
      tag_ids: formState.plotTag,
      cover_tag: formState.cornerMark,
      weight: formState.basicWeight,
      next_project_drama_id: formState.nextDramaID,
      play_count: formState.basicPlayNum,
      start_pay_eq: formState.payFrom,
      fee: formState.perPay,
      default_pay_button: formState.payFromChecked ? 1 : 0,
      default_fee_button: formState.perPayChecked ? 1 : 0,
      ad_unlock: formState.ad_unlock ? 1 : 0,
      status: formState.shelfChecked ? 1 : 0,
      is_show: formState.isShow ? 1 : 0,
      talent_promotion: formState.talent_promotion ? 1 : 0,
      talent_material: formState.talent_material,
      copyright_start_at: detail.value.copyright_start_at,
      copyright_end_at: detail.value.copyright_end_at
    }
    editDrama(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  }))
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
// .form-upload-item {
//   margin-top: 10px;
//   margin-left: 10px;
//   position: relative;
//   width: 118px;
//   height: 148px;
//   border-radius: 4px;
//   overflow: hidden;
//   img {
//     display: inline-block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
//   &.horizontal {
//     width: 148px;
//     height: 118px;
//   }
// }
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
  height: 148px;
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.checkbox-form {
  position: absolute;
  right: 8px;
  bottom: 18px;
  width: 14px;
  height: 14px;
  margin-bottom: 0;
}
.upload-item-select {
  
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>