<template>
  <a-modal
    :width="800"
    :title="(detail && detail.remark_name) || '　'"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel"
  >
   <div class="content">
    <!-- 左边视图 -->
    <div class="left-view">
      <!-- 播放器 -->
      <div
        class="video-cover"
        :style="`height: ${!isDownload ? '100%' : 'calc(100% - 52px - 42px)'};`"
      >
        <video
          ref="RefVideoView"
          v-if="videoJson && videoJson.preview_url"
          autoplay
          controls
          class="video-view"
          controlsList="nodownload"
          @canplay="canPlay"
          :src="$pub.CDN_URL(videoJson.preview_url)"
        ></video>
      </div>
      <!-- 下载按钮 -->
      <a-button v-if="isDownload" class="download-btn" @click="touchDownload">下载源视频</a-button>
      <!-- 批量下载 -->
      <a v-if="isDownload" :href="detail.batch_download_url" target="_blank" class="download-all">批量下载全部剧集</a>
    </div>
    <!-- 右边视图 -->
    <div class="right-view">
      <!-- 集数 -->
      <div
        v-for="(item, index) in dataSource"
        :class="`eq-btn ${item.eq_number === (videoJson && videoJson.eq_number) ? 'active' : ''}`"
        :key="item.eq_number"
        @click="touchPlay(item, index)"
      >
        {{ item.eq_number }}
      </div>
    </div>
   </div>
  </a-modal>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import { message } from 'ant-design-vue'
import { dramaDetail, videoList } from '@/api/copyright'
import { DOWLOAD_PROXY_ALL, DOWLOAD_FILE_URL_PRO } from '@/utils/download'
import axios from '@/api/axios'
import Pub from '@/utils/public'

// 视频播放器
let RefVideoView = ref(null)
// 是否展示弹窗
let visible = ref(false)
// 剧集信息
let detail = ref({})
// 视频对象
let videoJson = ref({})
// 当前播放索引
let videoJsonIndex = ref(0)
// 加载
let isLoading = ref(false)
// 下载
let isDownload = ref(true)
// 数据源
let dataSource = ref([])

// 展示弹窗
function showModal (id, eq, download = true) {
  // 清空
  detail.value = {}
  videoJson.value = {}
  isDownload.value = download ? true : false
  // 显示
  visible.value = true
  // 获取详情
  getDetail(id, eq)
  // 获取列表
  getList(id, eq)
}
// 关闭弹窗
function handleCancel () {
  if (videoJson.value) {
    videoJson.value = {}
  }
  visible.value = false
}
// 获取列表
function getList (id, eq) {
  isLoading.value = true
  const params = {
    drama_id: id,
    page: 1,
    page_size: 10000
  }
  videoList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data.data
      let item = {}
      if (eq) {
        item = dataSource.value.find((item, index) => {
          videoJsonIndex.value = index
          return item.eq_number === eq
        })
      } else {
        videoJsonIndex.value = 0
        item= dataSource.value[0]
      }
      videoJson.value = item
      if (!item.preview_url) {
        message.error('当前集数视频链接不存在')
      }
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}
// 获取详情
function getDetail (id) {
  dramaDetail({ id: id }).then(res => {
    const { code, data } = res
    if (code === 0) {
      // 存储详情
      detail.value = data
    }
  })
}

// 能播放
function canPlay () {
  // 播放器
  const video = RefVideoView.value
  // 添加播放计时器监听
  video.addEventListener("timeupdate", () => {
    // 总时间
    const timeDisplay = Math.floor(video.currentTime)
    // 当前时间
    const duration = Math.floor(video.duration)
    // 播放完成
    if (timeDisplay === duration) {
      // 下一集 index
      const index = videoJsonIndex.value + 1
      // 不是最后一集
      if (index < dataSource.value.length) {
        // 开始播放
        touchPlay(dataSource.value[index], index)
      }
    }
  }, false)
}

// 下载源视频
function touchDownload () {
  DOWLOAD_PROXY_ALL(videoJson.value.url)
  // DOWLOAD_FILE_URL_PRO(videoJson.value.url)
  // axios({
  //   url: videoJson.value.url,
  //   method: 'get',
  //   // method: 'post',
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //     // 'Access-Control-Allow-Origin': 'https://video-test.juhaokanya.com',
  //     // 'Access-Control-Allow-Headers': '*',
  //     // 'Access-Control-Allow-Credentials': '*',
  //     // 'Access-Control-Allow-Methods': 'GET, POST',
  //     // 'Access-Control-Max-Age': 1000,
  //     // 'Content-type': 'application/json',
  //   }
  // }).then(res => {
  //   console.log('成功', res)
  // }).catch(err => {
  //   console.log('失败', err)
  // })
}

// 进行播放
function touchPlay (item, index) {
  if (!item.preview_url) {
    message.error('当前集数视频链接不存在')
  }
  videoJsonIndex.value = index
  videoJson.value = item
}

defineExpose({
  showModal
})

</script>

<style lang="less" scoped>
.content {
  height: 600px;
  display: flex;
  .left-view {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .video-cover {
      width: 100%;
      height: 100%;
      background-color: black;
      .video-view {
        width: 100%;
        height: 100%;
      }
    }
    .download-btn {
      margin-top: 20px;
      width: 100px;
    }
    .download-all {
      margin-top: 20px;
    }
  }
  .right-view {
    flex: 1;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-left: 20px;
    overflow-y: auto;
    .eq-btn {
      width: 50px;
      height: 50px;
      background-color: #F2F2F2;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4px;
      cursor: pointer;
    }
    .eq-btn.active {
      border: 1px solid #21A3FB;
    }
  }
}
</style>