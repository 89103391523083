<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="data && data.remark_name"
    width="900"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 文件名 -->
        <template v-if="column.key === 'name'">
          {{ record.name || '未上传' }}
        </template>
        <!-- 数字显示兼容 -->
        <template v-if="['visit_count', 'play_count', 'play_over_count', 'unlock_count'].includes(column.key)">
          {{ record[column.key] || 0 }}
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'operation'">
          <a-button type="link" :disabled="!record.preview_url" @click="touchPreview(record)">预览</a-button>
          <a-button type="link" :disabled="!record.video_id" @click="touchIntroduction(record)">剧集简介</a-button>
        </template>
      </template>
    </a-table>
    <!-- 预览 -->
    <PreviewVideoProModal ref="RefPreviewModal"></PreviewVideoProModal>
    <!-- 简介 -->
    <Introduction ref="RefIntroduction" @success="getList"></Introduction>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import Introduction from './Introduction'
import PreviewVideoProModal from '@/components/preview/PreviewVideoProModal'
import { getDramaSeriesList } from '@/api/operate'

// 剧集简介抽屉实例
let RefIntroduction = ref(null)
// 视频预览弹窗实例
let RefPreviewModal = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 剧集信息
let data = ref({})
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  size: 'small',
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '集数',
    dataIndex: 'eq_number',
    key: 'eq_number'
  },
  {
    title: '文件名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '收费',
    dataIndex: 'is_fee',
    key: 'is_fee'
  },
  {
    title: '访问',
    dataIndex: 'visit_count',
    key: 'visit_count'
  },
  {
    title: '播放',
    dataIndex: 'play_count',
    key: 'play_count'
  },
  {
    title: '播放完成',
    dataIndex: 'play_over_count',
    key: 'play_over_count'
  },
  // {
  //   title: '播放完成率',
  //   dataIndex: 'up_date',
  //   key: 'up_date'
  // },
  {
    title: '非会员解锁',
    dataIndex: 'unlock_count',
    key: 'unlock_count'
  },
  // {
  //   title: '解锁率',
  //   dataIndex: 'rate_s',
  //   key: 'rate_s'
  // },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 打开抽屉
function showDrawer (record) {
  visible.value = true
  data.value = record
  // 获取剧集列表
  getList()
}

// 获取列表
function getList () {
  isLoading.value = true
  const params = {
    project_drama_id: data.value.id,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  getDramaSeriesList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    pagination.current = 1
    pagination.pageSize = 10
    visible.value = false
  }
}

// 预览
function touchPreview (record) {
  RefPreviewModal.value.showModal(record.drama_id, record.eq_number)
}

// 剧集简介
function touchIntroduction (record) {
  RefIntroduction.value.showDrawer(record, data.value.id)
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.ant-btn-link {
  padding: 0;
  margin-left: 8px;
} 
</style>